.printable-header {
  display: none;
}
@media print {
  .not-printable-header {
    display: none;
  }
  .card {
    border-style: hidden;
    border-width: 0;
  }
  .card-header {
    display: none;
  }
  .card-footer {
    display: none;
  }
  .card-body input[type='text'] {
    border-style: hidden;
  }
  .printable-header {
    display: block;
    padding: 30px;
  }
  .printable-header .logo {
    width: 30%;
  }
  table.table-materials,
  table.table-materials th,
  table.table-materials td {
    border: 1px solid #ccc;
  }
  .print-align-ceenter {
    display: flex;
    justify-content: center;
  }
}
